.work_container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;
}

.work_item {
  background: var(--first-color-lighter);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--first-color);
  height: fit-content;
  transition: var(--transition);
  color: var(--experience-text-color);
}

.work_title {
  background: var(--first-color--second);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
  color: var(--experience-text-color);
}

.work_title h3 {
  color: var(--experience-text-color);
  font-size: 1rem;
  text-align: center;
}

.work_title h4,
h5 {
  text-align: center;
  color: var(--experience-text-color);
}

.work-description {
  padding: 1rem;
}

.work_button {
  justify-content: center;
}

.bullet_points {
  padding-left: 2rem;
  padding-top: 0;
}

.project {
  font-weight: bold;
  padding: 1rem;
}

.work-description li {
  font-size: 0.9rem;
}

.work-description li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.bullet {
  color: var(--first-color);
  margin-top: 2px;
}

@media screen and (max-width: 1024px) {
  .work_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .work_item {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .work_container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
