.skills_image_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.logo {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  margin: 2rem;
}

.whoamibox {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.cmd {
  color: green;
  font-family: monospace;
  font-style: normal;
  font-size: larger;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.image-grid img {
  width: 100%;
  height: 100px; /* Set the desired height */
  object-fit: cover; /* Maintain aspect ratio and fill container */
}

.skill_name {
  color: var(--text-color);
}

.skills_container > div {
  background: transparent;
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.skills_container > div:hover {
  background: var(--first-color-lighter);
  border-color: var(--color-light);
  color: black;
}

.skills_container > h4:hover {
  color: black;
}

.skills_container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--first-color-alt);
}

.skills_content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}

.skills_details {
  display: flex;
  gap: 1rem;
}

.skills_icon {
  margin-top: 6px;
  color: var(--color-black);
}

@media screen and (max-width: 1024px) {
  .skills_container {
    grid-template-columns: 1fr;
  }

  .skills_container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .skills_content {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .skills_container {
    gap: 1rem;
  }

  .skills_container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
