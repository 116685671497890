/* GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/* Reusable CSS Classes */
.section {
  padding: 2rem 0 4rem;
}

.section_title {
  font-size: var(--h1-font-size);
}

.section_subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin: var(--mb-3);
}

.section_title,
.section_subtitle {
  text-align: center;
}

/* LAYOUT */
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

@media screen and (max-width: 768px) {
  .nav_menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}

/* HOME */
.home_container {
  gap: 1rem;
}

.home_content {
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  align-items: center;
}

.home_social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.web-icon {
  font-size: 1.25rem;
  width: 30px;
  margin-left: -3px;
}

.home_social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home_social-icon:hover {
  color: var(--first-color-alt);
}

.home_blob {
  width: 200px;
  fill: var(--first-color);
}

.home_blob-img {
  width: 100%;
  position: relative;
  object-fit: contain;
  margin-left: 100px;
}

.home_data {
  grid-column: 1/3;
}

.home_title {
  font-size: var(--big-font-size);
}

.home_subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home_description {
  margin-bottom: var(--mb-2);
}

.home_scroll {
  display: none;
}

.home_scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home_scroll-button:hover {
  transform: translateY(0.25rem);
}

.home_scroll-mouse {
  font-size: 2rem;
}

.home_scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
}

.home_scroll-arrow {
  font-size: 1.25rem;
}

/* Media Quesries For display*/

/* for phones */
@media screen and (max-width: 350px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }

  .nav_menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav_list {
    column-gap: 0;
  }

  .home_content {
    grid-template-columns: 0.25fr 3fr;
  }
  .home_blob {
    width: 180px;
  }

  .skills_title {
    font-size: var(--normal-font-size);
  }
  .qualification_data {
    gap: 0.5rem;
  }

  .project_img {
    width: 200px;
  }
}

/* for medium screeens */
@media screen and (min-width: 568px) {
  .home_content {
    grid-template-columns: max-content 1fr 1fr;
  }

  .home_data {
    grid-column: initial;
  }

  .home_img {
    order: 1;
    justify-self: center;
  }

  .about_container,
  .skills_container,
  .portfolio_content,
  .contact_container,
  .footer_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .qualification_sections {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

/* for medium screens */
@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }
  .section_subtitle {
    margin-bottom: 4rem;
  }

  .header {
    top: 0;
    bottom: initial;
  }

  .header,
  .main,
  .footer_container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav_icon,
  .nav_close,
  .nav_toggle {
    display: none;
  }

  .nav_list {
    display: flex;
    column-gap: 2rem;
  }

  .nav_menu {
    margin-left: auto;
  }

  .change-theme {
    margin: 0;
  }

  .home_container {
    row-gap: 5rem;
  }

  .home_content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home_blob {
    width: 270px;
  }

  .about_container {
    column-gap: 5rem;
  }

  .about_img {
    width: 350px;
  }

  .about_description {
    text-align: initial;
  }

  .about_info {
    justify-content: space-between;
  }

  .about_buttons {
    justify-content: initial;
  }

  .qualification_tabs {
    justify-content: center;
  }

  .qualification_button {
    margin: 0 var(--mb-1);
  }

  .qualification_sections {
    grid-template-columns: 0.5fr;
  }

  .portfolio_img {
    width: 320px;
  }

  .portfolio_content {
    align-items: center;
  }

  .footer_container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer_bg {
    padding: 3rem 0 3.5rem;
  }

  .footer_links {
    flex-direction: row;
    column-gap: 2rem;
  }

  .footer_socials {
    justify-self: flex-end;
  }

  .footer_copy {
    margin-top: 4.5rem;
  }
}

/* for large screens */
@media screen and (min-width: 1024px) {
  .header,
  .main,
  .footer_container {
    padding: 0;
  }

  .home_blob {
    width: 320px;
  }

  .home_social {
    transform: translateX(-6rem);
  }

  .contact_form {
    width: 460px;
  }

  .contact_inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}
