.footer {
  bottom: 0;
  width: 100%;
  padding: 3rem 0;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
}

.copyright {
  justify-content: center;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
