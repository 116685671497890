.projects_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.project_item {
  background: var(--first-color-lighter);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  color: var(--experience-text-color);
}

.project_item:hover {
  border-color: var(--first-color-lighter);
  background: transparent;
}

.project_img {
  border-radius: 1.5rem;
  overflow: hidden;
}

.project_item h3 {
  margin: 1.2rem 0 1rem;
  color: var(--experience-text-color);
  text-align: center;
}

.project_item p {
  margin-bottom: 1rem;
  color: var(--experience-text-color);
}

.project-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.github_button {
  border: 1px solid black;
  border-radius: 0.4rem;
}

.github_icon {
  color: var(--github-icon-color);
}

@media screen and (max-width: 1024px) {
  .projects_container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .projects_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
