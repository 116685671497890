.quote-div {
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 10px;
  margin-bottom: 3rem;
  color: var(--text-color);
  font-style: italic;
  font-weight: var(--font-medium);
}

.quote-div:hover {
  font-weight: var(--font-semi-bold);
}
