@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --header-height: 3rem;

  /* colors */
  /* 410 gold*/
  /* 210 blue */
  --hue-color: 407;
  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --experience-text-color: hsl(var(--hue-color), 8%, 45%);
  --github-icon-color: color: var(--first-color-alt);

  /* fonts */
  --body-font: "Poppins", sans-serif;

  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.23rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /* font weight */
  --font-medium: 500;
  --font-semi-bold: 600;

  /* margins */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* z index */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-midal: 1000;
}

/* ====================================DARK MODE================================= */
[data-theme="dark"] {
  --first-color: hsl(var(--hue-color), 30%, 61%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --first-color-lighter: hsl(var(--hue-color), 30%, 61%);
  --experience-text-color: black;
  --github-icon-color: black;
}
/* ============================================================================== */

html {
  scroll-behavior: smooth;
}

textarea {
  color: green;
}

input {
  color: green;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

p {
  font-weight: var(--font-semi-bold);
}
/* BUTTONS */

.button {
  width: max-content;
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button {
  margin-right: 1rem;
}

.button_icon {
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
  margin-right: auto;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--flex:hover {
  display: inline-flex;
  align-items: center;
  background-blend-mode: transparent;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 2rem 0 4rem;
}

section > h2,
section > h5 {
  text-align: center;
}

section > h2 {
  margin-bottom: 2rem;
}

section > h5 {
  margin-bottom: 2rem;
}

.section {
  padding: 2rem 0 4rem;
}

.section_title {
  font-size: var(--h1-font-size);
}

.section_subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin: var(--mb-3);
}

.section_title,
.section_subtitle {
  text-align: center;
}

.text-light {
  color: var(--text-color-light);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--first-color-alt);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--first-color);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/* Media Queries */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
